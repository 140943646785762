@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans");
@import url("https://fonts.googleapis.com/css?family=Rock+Salt");

:root {
    --green-000: #001000;
    --green-100: #202220;
    --green-200: #313531;
    --green-500: #20af4b;
    --green-900: #9beeb4;
}

::selection {
    background: var(--green-500);
    color: white;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
}

body {
    background: var(--green-200);
    font-family: "Merriweather Sans";
    color: var(--green-900);
}

h1, h2, h3 {
    color: var(--green-500);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

.body {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.circle {
    border-radius: 50%;
}

.flex-row > p {
    width: 100%;
}

.flex-row > .row-header {
    color: var(--green-500);
    margin: 1rem;
    text-align: right;
    text-shadow:
    1px 1px var(--green-000),
    0 0 4px var(--green-000);
    width: 16rem;
}

.shadow {
    border: 1px solid var(--green-000);
    filter: drop-shadow(0 0 0.25rem var(--green-000)) drop-shadow(0 0 1rem var(--green-000));
}

.hr-fade-to-left {
    background-image: linear-gradient(-90deg, var(--green-100), var(--green-500) 32px, var(--green-500) 50%, var(--green-100));
    height: 1px;
    margin: 2rem 0;
}

.hr-fade-to-right {
    background-image: linear-gradient(90deg, transparent, var(--green-500) 32px, var(--green-500) 50%, transparent);
    height: 1px;
    margin: 2rem 0;
}

.logo-text {
    color: var(--green-000);
    font-family: "Rock Salt";
    font-size: 2em;
    left: 24px;
    position: absolute;
    text-shadow:
    1px 1px var(--green-900),
    0 0 4px var(--green-900);
    top: -6px;
    transform: rotate(-4deg);
    z-index: 10;
}

.nav-bar {
    background: var(--green-100);
    border-bottom: 1px solid var(--green-000);
    box-shadow: 0 2px 8px var(--green-000);
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
}

.nav-bar-button {
    color: var(--green-500);
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
    padding: 4px 12px;
}

.nav-bar-button--selected {
    background: var(--green-500);
    box-shadow: 0 0 16px var(--green-000);
    color: var(--green-100);
    cursor: default;
}

.nav-bar-button-container {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

.flex-column {
    margin: 4rem;
    max-width: 64rem;
}

.flex-row {
    align-items: center;
    display: flex;
    gap: 2rem;
    height: fit-content;
    justify-content: left;
}

.portfolio-card {
    background: var(--green-100);
    box-shadow: inset 0 0 1rem var(--green-000);
    border-radius: 1rem;
    display: flex;
    margin: 3rem 0;
}

.portfolio-card:first-of-type {
    margin: 1.5rem 0 3rem;
}

.portfolio-card > a {
    margin: 0 0 -0.25rem;
}

.portfolio-card-header {
    margin: 0 0 0.5rem;
}

.portfolio-card-link {
    color: white;
}

.portfolio-card-image {
    border: 1px solid var(--green-000);
    margin: 0 0 auto;
    width: 360px;
}

.portfolio-card-text-area {
    margin: 0.75rem 1.25rem;
    text-shadow:
    1px 1px var(--green-000),
    0 0 4px var(--green-000);
}

.paragraph-container {
    color: var(--green-900);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.5rem;
    text-align: justify;
}

.hero-img {
    height: 16rem;
}

.header {
    color: var(--green-500);
    margin-bottom: 0.5rem;
    text-shadow:
    1px 1px var(--green-000),
    0 0 4px var(--green-000);
}

footer > h2 {
    margin-bottom: 16px;
    text-align: center;
    text-shadow:
    1px 1px var(--green-000),
    0 0 4px var(--green-000);
    width: 100;
}

.under-construction {
    text-align: center;
}

.under-construction a {
    background: var(--green-500);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem var(--green-000);
    color: var(--green-100);
    font-size: 1.25rem;
    padding: 8px 16px;
    transition: 100ms;
}

.under-construction a:hover {
    background: var(--green-900);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem var(--green-000);
    color: var(--green-200);
    font-size: 1.25rem;
    padding: 8px 16px;
}